.form-toggle {
  @apply mr-7;
  @apply text-sm md:text-xl text-darkGrey;
  font-family: 'Poppins';

  >input:checked + span {
    @apply font-bold;
  }
}


form {
  h3 {
    @apply mb-16;
    font-family: 'ITC';
  }

  fieldset {
    @apply flex flex-col w-4/5 mx-auto;

    .title-orange {
      @apply text-orange my-10;
      font-family: 'ITC';
    }

    label, textarea {
      @apply py-2 px-7 mb-2;
      @apply border border-lightGrey rounded-md bg-white;
      font-family: 'Poppins';

      input {
        @apply w-full outline-none;
      }
    }

    .chk-section {
      @apply my-4 md:my-8;
    }

    .select-section {
      @apply my-4 md:my-8;
    }

    .chk-wrapper {
      @apply flex flex-col md:flex-row my-4 gap-4 md:gap-16;

      .chk-col:first-child {
        @apply text-darkBlue text-xl;
        font-family: 'Poppins';
      }

      .chk-col {
        @apply block;

        label {
          @apply bg-transparent;
        }
      }

      label {
        @apply border-0 pl-3 pr-10 text-darkerGrey;
      }
    }

    select {
      @apply w-full;
      @apply border border-lightGrey rounded-md;
      @apply py-2 px-7 mt-2 md:mt-5 mb-1 md:mb-2;
      @apply text-grey;
      font-family: 'Poppins';
    }

    .files {
      @apply flex flex-row gap-10 mt-8;

      span {
        @apply self-center;
        @apply text-darkBlue text-xl;
        font-family: 'Poppins';
      }

      label {
        @apply w-fit cursor-pointer;
        @apply bg-transparent border border-orange;
        @apply text-orange text-base font-normal;

        input {
          @apply hidden;
        }
      }
    }

    input[type = submit] {
      @apply mt-5 px-9 py-3 w-fit mx-auto;
      @apply border border-darkBlue;
      @apply text-darkBlue font-semibold;
      @apply cursor-pointer;
    }
  }
}
