.header-wrapper {
    @apply h-24;
}
    
.layout {
    @apply relative;

    .layout-container {
        @apply flex container bg-lighterBlue lg:bg-transparent;

        @media (max-width: 1280px) {
            max-width:unset !important;
        }

        .menu-wrapper {
            @apply w-0 lg:w-1/4 bg-lighterBlue;

            @media (max-width: 1024px) { width:0% }
          
        }

        >div:nth-child(2) {
            @apply w-11/12 lg:w-3/4 mx-auto;
            min-height: calc(100vh - 6rem);

            @media (max-width: 1024px) { width:100% }
        }

        .menu-mobile {
            @apply block lg:hidden;
        }
    }

    .background {
        @apply absolute h-full w-full hidden md:flex pointer-events-none -z-10;

        &__left {
            @apply bg-white w-1/4 h-full;
        }

        &__right {
            @apply bg-lighterBlue w-3/4 h-full;
        }
    }
}
