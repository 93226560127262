/** Tailwind base styles */
@tailwind base;

/** Import everything from autoload */
// @import "./autoload/**/*";

/** Tailwind plugins */
@tailwind components;

/** Your own components – checkout @apply */
@import 'common/global';
@import 'common/fonts';

@import 'components/forms';
@import 'components/buttons';
@import 'components/forms';
@import 'components/links';
@import 'components/menu';
@import 'components/tabs';
@import 'components/domains-tab';
@import 'components/searchbar';
@import 'components/list-component';
@import 'components/list-members-component';
@import 'components/mosaic-component';
@import 'components/mosaic-list-switch';
@import 'components/menu-mobile';
@import 'components/folder-tab';
@import 'components/tools-tab';
@import 'components/breadcrumbs';
@import 'components/dropdown';
@import 'components/dropdown-comite';
@import 'components/pagination';
@import 'components/loading';

@import 'accueil';
@import 'questions';
@import 'header.scss';
@import 'login.scss';
@import 'layout.scss';
@import 'appel-doffres';
@import 'conseil-admin';
@import 'single-produits-informatiques';
@import 'espace-collab';
@import 'fournisseurs';
@import 'publications';
@import 'comites-expertises';

/** Tailwind utilities */
@tailwind utilities;
