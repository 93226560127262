.menu {
    @apply bg-white z-10;
    @apply hidden lg:block;
    @apply fixed w-1/4;
    @apply overflow-y-scroll;
    height: calc(100% - 6rem);
    max-width: 350px;


    &__logo {
        width:107px;
        @apply mt-5 ml-1;
    }

    h2 {
        @apply mt-5 ml-4;
    }

    p {
        @apply text-2xl font-bold text-orange mt-1 ml-4;
    }

    .user-info {
        @apply flex items-center gap-5;

        svg {
            @apply text-lightGrey text-xl -rotate-90 self-end;
        }
    }

    ul {
        @apply mt-5 w-11/12;

        li {
            @apply flex items-center gap-4;
            @apply py-3 px-5;
            
            a {
                @apply text-lg font-normal;
            }

            &.active {
                @apply bg-lightBlue rounded-lg;

                a {
                    @apply text-lg font-bold;
                }
            }

            &.logout {
                @apply border-t border-lighterGrey;
            }
        }
    }
}